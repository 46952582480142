<template>
  <div class="amounts-viewer-wrapper">
    <h1>Amounts</h1>
    <Button @click="openAmountAdder()">Add amount</Button>
    <AmountAdder
      v-if="showAmountAdder"
      @close="closeAmountAdder()"
      :accountsProp="accountsProp"
      :expensesProp="expensesProp"
      @addAmount="addAmount"
    />

    <SectionToggler
      @toggle="setShowRecurrentExpenses"
      :text="'Recurrent expenses'"
    />
    <AmountViewer
      v-if="showRecurrentExpenses"
      @saveAmount="saveAmount"
      v-for="expense in getRecurrentExpenses()"
      :key="expense"
      :amountProp="expense"
      :expensesProp="expensesProp"
    />

    <SectionToggler @toggle="setShowExpenses" :text="'Expenses'" />
    <AmountViewer
      v-if="showExpenses"
      @saveAmount="saveAmount"
      v-for="expense in getExpenses()"
      :key="expense"
      :amountProp="expense"
      :expensesProp="expensesProp"
    />

    <SectionToggler @toggle="setShowIncomes" :text="'Incomes'" />
    <AmountViewer
      v-if="showIncomes"
      @saveAmount="saveAmount"
      v-for="income in getIncomes()"
      :key="income"
      :amountProp="income"
      :expensesProp="expensesProp"
    />

    <SectionToggler @toggle="setShowRefunds" :text="'Refunds'" />
    <AmountViewer
      v-if="showRefunds"
      @saveAmount="saveAmount"
      v-for="refund in getRefunds()"
      :key="refund"
      :amountProp="refund"
      :expensesProp="expensesProp"
    />
  </div>
</template>

<script>
import AmountAdder from "./AmountAdder.vue";
import AmountViewer from "./AmountViewer.vue";
import SectionToggler from "../SectionToggler.vue";

export default {
  components: {
    AmountAdder,
    AmountViewer,
    SectionToggler,
  },
  data() {
    return {
      showRecurrentExpenses: false,
      showExpenses: false,
      showIncomes: false,
      showRefunds: false,
      showAmountAdder: false,
    };
  },
  props: {
    amountsProp: [],
    expensesProp: [],
    accountsProp: [],
  },
  methods: {
    getRecurrentExpenses() {
      return this.amountsProp.filter(
        (amount) =>
          amount.type.toUpperCase().includes("EXPENSE-") &&
          amount.page_id == this.$route.params.pageId &&
          amount.type.toUpperCase() !== "EXPENSE-ONETIME"
      );
    },
    getFilteredAmount(type) {
      return this.amountsProp.filter(
        (amount) =>
          amount.type.toUpperCase().includes(type) &&
          amount.page_id == this.$route.params.pageId
      );
    },
    closeAmountAdder() {
      this.showAmountAdder = false;
    },
    openAmountAdder() {
      this.showAmountAdder = true;
    },
    getExpenses() {
      return this.getFilteredAmount("EXPENSE-ONETIME");
    },
    getIncomes() {
      return this.getFilteredAmount("INCOME");
    },
    getRefunds() {
      return this.getFilteredAmount("REFUND");
    },
    saveAmount(amount) {
      this.$emit("saveAmount", amount);
    },
    addAmount(amount) {
      this.$emit("addAmount", amount);
    },
    setShowRecurrentExpenses(value) {
      this.showRecurrentExpenses = value;
    },
    setShowExpenses(value) {
      this.showExpenses = value;
    },
    setShowIncomes(value) {
      this.showIncomes = value;
    },
    setShowRefunds(value) {
      this.showRefunds = value;
    },
  },
};
</script>

<style></style>
