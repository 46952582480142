<template>
  <BarChart
    v-if="is_graph_type('BAR-CHART')"
    :key="refreshChartIncrement"
    :filtersProp="getFilters()"
    :labelsProp="getLabels()"
    :datasetsProp="getDatasets()"
    @filterValueChanged="setFilter"
  />

  <PieChart
    v-if="is_graph_type('PIE-CHART')"
    :key="refreshChartIncrement"
    :labelsProp="getLabels()"
    :valuesProp="getValues()"
    :filtersProp="getFilters()"
    @filterValueChanged="setFilter"
  />

  <LineChart
    v-if="is_graph_type('LINE-CHART')"
    :key="refreshChartIncrement"
    :valuesProp="getValues()"
    :labelsProp="getLabels()"
    :filtersProp="getFilters()"
    @filterValueChanged="setFilter"
  />
</template>

<script>
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import LineChart from "./LineChart.vue";
import Toolbox from "@/tools/toolbox";

export default {
  components: {
    PieChart,
    BarChart,
    LineChart,
  },
  props: {
    graphTypeProp: "",
    rawDataProp: [],
  },
  data() {
    return {
      apiData: [],
      startDateParamName: "Start date",
      endDateParamName: "End date",
      startDate: null,
      endDate: null,
      refreshChartIncrement: 0,
    };
  },
  watch: {
    rawDataProp(value, oldValue) {
      if (value) {
        this.apiData = value;
        this.refreshChartIncrement++;
      }
    },
  },
  methods: {
    getDatasets() {
      return [
        {
          backgroundColor: Toolbox.getThemeColors()[0],
          data: this.getValues(),
          label: "Expenses",
        },
      ];
    },
    getLabels() {
      let groupedData = this.getGroupedData(this.getFilteredData(this.apiData));
      let labels = [];

      for (let i = 0; i < groupedData.length; i++) {
        labels.push(groupedData[i].Label);
      }

      return labels;
    },
    getValues() {
      let groupedData = this.getGroupedData(this.getFilteredData(this.apiData));
      let values = [];

      for (let i = 0; i < groupedData.length; i++) {
        values.push(groupedData[i].Value);
      }

      return values;
    },
    getFilters() {
      return [
        { param: this.startDateParamName, value: this.startDate, type: "date" },
        { param: this.endDateParamName, value: this.endDate, type: "date" },
      ];
    },
    setFilter(filter) {
      if (filter.param == this.startDateParamName)
        this.startDate = filter.value;
      else if (filter.param == this.endDateParamName)
        this.endDate = filter.value;

      this.refreshChartIncrement++;
    },
    getGroupedData(filteredData) {
      let data = filteredData.reduce(
        (acc, currentValue) => {
          /*We check for an existing key*/
          let elements = acc.filter(
            (x) => x.Label == currentValue.Expense_Name
          );

          //If there is not, we add it
          if (elements.length == 0) {
            acc.push({
              Label: currentValue.Expense_Name,
              Value: currentValue.Amount,
            });
          }
          //if there is, we update it
          else {
            elements[0].Value += currentValue.Amount;
          }
          return acc;
        },
        [] /*Default value of groupedData*/
      );
      return data;
    },
    getFilteredData(data) {
      if (this.startDate)
        data = data.filter((x) => x.Transaction_Date >= this.startDate);
      if (this.endDate)
        data = data.filter((x) => x.Transaction_Date <= this.endDate);

      return data;
    },
    is_graph_type(value) {
      return this.graphTypeProp == value;
    },
  },
};
</script>

<style></style>
