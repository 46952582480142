<template>
  <!-- <div class="expense-selector-wrapper"> -->
  <select v-model="expense" @change="selectExpense">
    <option v-for="expense in expensesProp" :key="expense.id" :value="expense">
      {{ expense.name }}
    </option>
  </select>
  <!-- </div> -->
</template>

<script>
import Toolbox from "../../tools/toolbox.js";

export default {
  props: {
    expenseIdProp: Number,
    expensesProp: [],
  },
  data() {
    return {
      expense: {},
    };
  },
  watch: {
    expensesProp(value, oldValue) {
      if (value && this.expenseIdProp) {
        this.expense = Toolbox.cloneObject(
          value.find((exp) => exp.id == this.expenseIdProp)
        );
      }
    },
    expenseIdProp(value, oldValue) {
      //Reset component
      if (value == -1) this.expense = {};
      else if (value && this.expensesProp) {
        this.expense = Toolbox.cloneObject(
          this.expensesProp.find((exp) => exp.id == value)
        );
      }
    },
  },
  methods: {
    selectExpense() {
      this.$emit("selectedExpenseChanged", Toolbox.cloneObject(this.expense));
    },
  },
};
</script>

<style></style>
