<template>
  <div class="chart-wrapper">
    <Line :data="chartData" :options="options" />

    <div class="graph-filter-wrapper">
      <div v-for="filter in filters" :key="filter">
        <label for="">{{ filter.param }}</label>
        <input
          v-model="filter.value"
          :type="filter.type"
          @input="publishFilter(filter)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

export default {
  name: "LineChart",
  components: {
    Line,
  },
  props: {
    labelsProp: [],
    datasetsProp: [],
    filtersProp: [],
    titleProp: "",
  },
  data() {
    return {
      chartData: {
        labels: this.labelsProp,
        datasets: this.datasetsProp,
      },
      options: {
        responsive: true,
      },
      filters: this.filtersProp,
    };
  },
  methods: {
    publishFilter(filter) {
      this.$emit("filterValueChanged", filter);
    },
  },
};
</script>

<style></style>
