<template>
  <table>
    <tr>
        <td>
          <input v-model="account.description" type="text">
        </td>
        <td>
          <input :value="parseFloat(account.total).toFixed(2)" type="number">
        </td>
        <td>
          <button v-if="hasChanged()" @click="save">Sauvegarder</button>
        </td>
        <td>
          <button @click="toggleTransactions()">{{getToggleButtonText()}}</button>
        </td>
    </tr>
  </table>
<TransactionViewer v-if="showTransactions" :isAbsoluteProp="true" :transactionsProp="transactionsProp"/>
</template>

<script>
import Toolbox from "../../tools/toolbox.js"
import TransactionViewer from "../transaction/TransactionsViewer.vue"

export default {
    props:{
        accountProp: Object,
        transactionsProp : []
    },
    watch:{
      accountProp(value){
        this.account = Toolbox.cloneObject(this.accountProp);
        this.lastSavedAccount = Toolbox.cloneObject(this.accountProp);
      }
    },
    components:{
      TransactionViewer
    },
    data(){
        return{
            account : Toolbox.cloneObject(this.accountProp),
            lastSavedAccount : Toolbox.cloneObject(this.accountProp),
            showTransactions : false
        }
    },
    methods:{
        save(){
            this.$emit("saveAccount", this.account)
            this.lastSavedAccount = Toolbox.cloneObject(this.account)
        },
        hasChanged(){
            return JSON.stringify(this.account) !== JSON.stringify(this.lastSavedAccount)
        },
        toggleTransactions(){
          this.showTransactions = !this.showTransactions;
        },
        getToggleButtonText(){
          if(this.showTransactions)
            return "Hide transactions"
          return "Show transactions";
        },
        getRoundedValue(value){
          return parseFloat(value).toFixed(2);
        }
    }
}
</script>

<style>

</style>