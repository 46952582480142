<template>
  <div class="summary-grid-wrapper">
    <h1>Summary</h1>
    <table>
      <tr>
        <th>Description</th>
        <th>Montant</th>
      </tr>
      <tr>
        <td>Previous total wealth</td>
        <td>{{ round(getField("Previous total wealth")) }}</td>
      </tr>
      <tr>
        <td>Expenses</td>
        <td>{{ round(getField("Expenses")) }}</td>
      </tr>
      <tr>
        <td>Recurrent expenses</td>
        <td>{{ round(getField("Recurrent expenses")) }}</td>
      </tr>
      <tr>
        <td>Incomes</td>
        <td>{{ round(getField("Incomes")) }}</td>
      </tr>
      <tr>
        <td>Refunds</td>
        <td>{{ round(getField("Refunds")) }}</td>
      </tr>
      <tr>
        <td>Total Primay accounts</td>
        <td>{{ round(getField("Total primary accounts")) }}</td>
      </tr>
      <tr>
        <td>Total Recurrent expenses accounts</td>
        <td>{{ round(getField("Total reccurent expense accounts")) }}</td>
      </tr>
      <tr>
        <td>Total wealth</td>
        <td>{{ round(getField("Total wealth")) }}</td>
      </tr>
      <tr>
        <td>Wealth variation</td>
        <td>{{ round(getField("Wealth variation")) }}</td>
      </tr>
      <tr>
        <td>Entrys - exits</td>
        <td>{{ round(getField("Incomes") + getField("Refunds") - getField("Expenses") - getField("Recurrent expenses"))}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import Toolbox from "../tools/toolbox.js";

export default {
  props: {
    summaryProp: [],
  },
  methods: {
    round(number) {
      return number.toFixed(2);
    },
    getField(field) {
      let row = this.summaryProp.filter((x) => x.title == field && x.page_id == this.$route.params.pageId)[0];
      if (row == null || row == undefined) return 0;
      else return row.amount;
    },
    getTotalWealth() {
      return (
        this.getField("Total primary accounts") +
        this.getField("Total recurrent expenses accounts")
      );
    }
  },
};
</script>

<style>
.summary-grid-wrapper{
  display : flex;
  flex-direction: column;
  align-items: center;
}

td:first-child,
th:first-child {
  text-align: left;
  margin-left: 5px;
}
td:nth-child(2),
th:nth-child(2) {
  text-align: right;
}
</style>
