<template>
  <div class="register-page-wrapper">
    <h2>
      Please fill out the form bellow to create an account and get started!
    </h2>
    <form id="register-form">
      <label v-if="passwordMistmatch" class="error"
        >Passwords do not match.</label
      >
      <div>
        <label for="name">Name :</label>
        <input type="text" v-model="user.name" id="name" />
      </div>
      <div>
        <label for="email">Email :</label>
        <input type="text" v-model="user.email" id="email" />
      </div>
      <div>
        <label for="password">Password :</label>
        <input type="text" v-model="user.password" id="password" />
      </div>
      <div>
        <label for="confirm-password">Confirm password :</label>
        <input
          type="text"
          v-model="user.confirmPassword"
          id="confirm-password"
        />
      </div>
      <button @click="register" class="big-button">Submit</button>
    </form>
  </div>
</template>

<script>
import ApiAdapter from "@/tools/apiAdapter";

export default {
  data() {
    return {
      user: {},
      passwordMistmatch: false,
    };
  },
  methods: {
    async register() {
      if (this.user.password != this.user.confirmPassword) {
        this.passwordMistmatch = true;
        return;
      }

      let value = await ApiAdapter.register(this.user);
      if (value) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style>
/*CSS is in the login page*/

#register-form {
  padding: 50px 150px;
}
</style>
