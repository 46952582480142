/* eslint-disable */

import $ from "jquery";
import storageAdapter from "./storageAdapter";

export default {
  getUrl() {
    return process.env.VUE_APP_API_URL;
  },
  async postRequest(url, content, success, error, type = "POST") {
    return new Promise((resolve) => {
      $.ajax({
        url: url,
        type: type,
        contentType: "application/json",
        beforeSend: function (xhr) {
          if (storageAdapter.getLoggedInUser() !== null)
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + storageAdapter.getLoggedInUser().token
            );
        },
        data: content,
        success: function (response) {
          resolve(success(response));
        },
        error: function (response) {
          resolve(error(response));
        },
      });
    });
  },
  async getRequest(url, success, error, type = "GET") {
    console.log(url);
    return new Promise((resolve) => {
      $.ajax({
        url: url,
        type: type,
        beforeSend: function (xhr) {
          if (storageAdapter.getLoggedInUser() !== null)
            xhr.setRequestHeader(
              "Authorization",
              "Bearer " + storageAdapter.getLoggedInUser().token
            );
        },
        success: function (response) {
          resolve(success(response));
        },
        error: function (response) {
          resolve(error(response));
        },
      });
    });
  },
  addAmount(amount) {
    return this.postRequest(
      this.getUrl() + "/api/amount",
      JSON.stringify(amount),
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to add the amount : " + response.responseJSON.message);
      }
    );
  },
  async addBudget(budget) {
    return this.postRequest(
      this.getUrl() + "/api/budget",
      JSON.stringify(budget),
      (response) => {
        return { budget_id: response.id, page_id: response.page_id };
      },
      (response) => {
        alert("Unable to add the amount : " + response.responseJSON.message);
      }
    );
  },
  async addAccount(account) {
    return this.postRequest(
      this.getUrl() + "/api/account",
      JSON.stringify(account),
      (response) => {
        return response.id;
      },
      (response) => {
        alert("Unable to add the amount : " + response.responseJSON.message);
      }
    );
  },
  async login(user) {
    return await this.postRequest(
      this.getUrl() + "/api/login",
      JSON.stringify(user),
      (response) => {
        storageAdapter.setLoggedInUser(JSON.stringify(response.user));
        return true;
      },
      (response) => {
        return false;
      }
    );
  },
  async register(user) {
    return await this.postRequest(
      this.getUrl() + "/api/signup",
      JSON.stringify(user),
      (response) => {
        storageAdapter.setLoggedInUser(JSON.stringify(response.user));
        return true;
      },
      (response) => {
        return false;
      }
    );
  },
  async getBudgets() {
    return await this.getRequest(
      this.getUrl() + "/api/budgets",
      (response) => {
        return response;
      },
      (response) => {
        return false;
      }
    );
  },
  async getAmounts(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/amounts/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to retrieve amounts : " + response.responseJSON.message);
        return [];
      }
    );
  },
  async getAccounts(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/accounts/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to retrieve accounts : " + response.responseJSON.message);
        return [];
      }
    );
  },
  async getPages(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/" + budgetId + "/pages",
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to retrieve pages : " + response.responseJSON.message);
        return [];
      }
    );
  },
  async getReccurentExpenses(budgetId, pageId) {
    return await this.getRequest(
      this.getUrl() + "/api/recurrentExpenses/" + budgetId + "/" + pageId,
      (response) => {
        return response;
      },
      (response) => {
        alert(
          "Unable to retrieve recurrent expenses : " +
            response.responseJSON.message
        );
        return [];
      }
    );
  },
  async addPage(budgetId, pageId, page) {
    page.previous_page_id = pageId;
    page.budget_id = budgetId;

    return this.postRequest(
      this.getUrl() + "/api/page",
      JSON.stringify(page),
      (response) => {
        return response.id;
      },
      (response) => {
        alert("Unable to add a page " + response.responseJSON.message);
      }
    );
  },
  async addTransaction(transaction) {
    return this.postRequest(
      this.getUrl() + "/api/transaction",
      JSON.stringify(transaction),
      (response) => {
        return response.id;
      },
      (response) => {
        alert("Unable to add a transaction " + response.responseJSON.message);
      }
    );
  },
  async editTransaction(transaction) {
    return this.postRequest(
      this.getUrl() + "/api/transaction/" + transaction.id,
      JSON.stringify(transaction),
      (response) => {
        return response.id;
      },
      (response) => {
        alert("Unable to edit a transaction " + response.responseJSON.message);
      },
      "PUT"
    );
  },
  async getTransactions(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/transactions/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert(
          "Unable to retrieve transactions : " + response.responseJSON.message
        );
        return [];
      }
    );
  },
  async getTransaction(transactionId) {
    return await this.getRequest(
      this.getUrl() + "/api/transaction/" + transactionId,
      (response) => {
        return response;
      },
      (response) => {
        alert(
          "Unable to retrieve transaction : " + response.responseJSON.message
        );
        return null;
      }
    );
  },
  async getExpenses(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/expenses/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to retrieve expenses : " + response.responseJSON.message);
        return [];
      }
    );
  },
  async editAmount(amount) {
    return await this.postRequest(
      this.getUrl() + "/api/amount/" + amount.id,
      JSON.stringify(amount),
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to edit amount : " + response.responseJSON.message);
        return null;
      },
      "PUT"
    );
  },
  async addExpense(expense) {
    return await this.postRequest(
      this.getUrl() + "/api/expense",
      JSON.stringify(expense),
      (response) => {
        return response.id;
      },
      (response) => {
        alert("Unable to add expense : " + response.responseJSON.message);
        return null;
      }
    );
  },
  async editExpense(expense) {
    return await this.postRequest(
      this.getUrl() + "/api/expense/" + expense.id,
      JSON.stringify(expense),
      (response) => {
        return response.success;
      },
      (response) => {
        alert("Unable to edit expense : " + response.responseJSON.message);
        return null;
      },
      "PUT"
    );
  },
  async deleteExpense(expenseId) {
    return await this.getRequest(
      this.getUrl() + "/api/expense/" + expenseId,
      (response) => {
        return true;
      },
      (response) => {
        alert("Unable to delete expense : " + response.responseJSON.message);
        return false;
      },
      "DELETE"
    );
  },

  ///Graphs
  async getGraphsExpenses(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/stats/expenses/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert("Unable to retrieve expenses : " + response.responseJSON.message);
        return [];
      }
    );
  },
  async getMonthlyStats(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/stats/monthly/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert(
          "Unable to retrieve monthly stats : " + response.responseJSON.message
        );
        return [];
      }
    );
  },
  async getMonthlyDiffs(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/stats/monthly-diffs/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        alert(
          "Unable to retrieve monthly diffs : " + response.responseJSON.message
        );
        return [];
      }
    );
  },
  async getSummary(budgetId) {
    return await this.getRequest(
      this.getUrl() + "/api/budget/summary/" + budgetId,
      (response) => {
        return response;
      },
      (response) => {
        console.log(response);
        alert(
          "Unable to retrieve summary : " + response?.responseJSON?.message
        );
        return [];
      }
    );
  },
};
