<template>
  <LineChart
    v-if="is_graph_type('LINE-CHART')"
    :key="refreshChartIncrement"
    :datasetsProp="getDatasets()"
    :labelsProp="getLabels()"
    :filtersProp="getFilters()"
    @filterValueChanged="setFilter"
  />

  <BarChart
    v-if="is_graph_type('BAR-CHART')"
    :key="refreshChartIncrement"
    :datasetsProp="getDatasets()"
    :labelsProp="getLabels()"
    :filtersProp="getFilters()"
    @filterValueChanged="setFilter"
  />
</template>

<script>
import Toolbox from "@/tools/toolbox";
import LineChart from "./LineChart.vue";
import BarChart from "./BarChart.vue";

export default {
  components: {
    LineChart,
    BarChart,
  },
  props: {
    graphTypeProp: "",
    rawDataProp: [],
  },
  data() {
    return {
      startDateParamName: "Start date",
      endDateParamName: "End date",
      startDate: null,
      endDate: null,
      refreshChartIncrement: 0,
    };
  },
  watch: {
    rawDataProp(value, oldValue) {
      if (value) {
        this.apiData = value;
        this.refreshChartIncrement++;
      }
    },
  },
  methods: {
    getDatasets() {
      if (this.rawDataProp) {
        let datasets = [];
        let data = this.getFilteredData(this.rawDataProp);

        //Get values
        let values = [];
        data.forEach((element) => {
          values.push(element.Amount);
        });
        datasets.push({
          data: values,
          label: "Month income vs expenses",
          backgroundColor: Toolbox.getThemeColors(),
        });

        return datasets;
      }
    },
    getLabels() {
      if (this.rawDataProp) {
        let labels = [];
        let data = this.getFilteredData(this.rawDataProp);

        data.forEach((element) => {
          labels.push(element.Month);
        });
        return labels;
      }
    },
    getFilters() {
      return [
        { param: this.startDateParamName, value: this.startDate, type: "date" },
        { param: this.endDateParamName, value: this.endDate, type: "date" },
      ];
    },
    setFilter(filter) {
      if (filter.param == this.startDateParamName)
        this.startDate = filter.value;
      else if (filter.param == this.endDateParamName)
        this.endDate = filter.value;

      this.refreshChartIncrement++;
    },
    getFilteredData(data) {
      if (this.startDate)
        data = data.filter((x) => x.Start_Date >= this.startDate);
      if (this.endDate) data = data.filter((x) => x.End_Date <= this.endDate);

      return data;
    },
    is_graph_type(value) {
      return this.graphTypeProp == value;
    },
  },
};
</script>

<style></style>
