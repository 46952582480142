<template>
  <div class="chart-wrapper">
    <Bar :data="chartData" :options="options" />

    <div class="graph-filter-wrapper">
      <div v-for="filter in filters" :key="filter">
        <label for="">{{ filter.param }}</label>
        <input
          v-model="filter.value"
          :type="filter.type"
          @input="publishFilter(filter)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors
);

export default {
  components: {
    Bar,
  },
  props: {
    labelsProp: [],
    valuesProp: [],
    filtersProp: [],
    datasetsProp: [],
  },
  data() {
    return {
      chartData: {
        labels: this.labelsProp,
        datasets: this.datasetsProp,
      },
      options: {
        responsive: true,
      },
      filters: this.filtersProp,
    };
  },
  methods: {
    publishFilter(filter) {
      this.$emit("filterValueChanged", filter);
    },
  },
};
</script>

<style></style>
